<template lang="pug">
  div.counts
    count-item(
      tooltip="Candidates"
      :classes="['green--text']"
    ) {{ item.candidates_count }}
    //- v-tooltip(top)
    //-   template(v-slot:activator="{ on }")
    //-     span(v-on="withTooltip ? on : null").counts__item.green--text
    //-   span Candidates

    count-item(
      tooltip="TOP CBR"
      hideTooltip
      :classes="['count-item_queue']"
    )
      v-tooltip(top )
        template(v-slot:activator="{ on }")
          span(v-on="on")
            template {{ item.top_cbr_reserve_queue_count }}
        span Reserve queue
      v-tooltip(top )
        template(v-slot:activator="{ on }")
          span(v-on="on")
            template | {{ item.top_cbr_exchange_queue_count }}
        span Exchange queue
    count-item(
      tooltip="Mijn CBR"
      :classes="['count-item_mijn']"
    ) {{ item.mijn_cbr_queue_count }}

    count-item(
      tooltip="Slots"
      :classes="['count-item_slots']"
    )
      span(v-if="!hideSlots") {{ item.slots_count }}
      span(v-else) -
</template>

<script>
import CountItem from "../CMCourseCountItem"

export default {
  props: {
    item: {
      Object,
      required: true,
    },
    withTooltip: {
      type: Boolean,
      default: false,
    },
    hideSlots: Boolean
  },

  components: {
    countItem: CountItem
  }
}
</script>

<style lang="scss" scoped>
.counts {
  display: flex;
  align-items: center;
  border-top: 1px solid $border-color;
}
</style>
